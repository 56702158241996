import React, { useState, useEffect } from "react"
import AnimateHeight from "react-animate-height"
import SymbolX from "../Symbols/symbol-x"
import ChevronDown from "../Symbols/chevron-down"
import { useIsJsEnabled } from "gatsby-plugin-js-fallback"

const ServiceItem = props => {
  const [height, setHeight] = useState(`auto`)

  useEffect(() => {
    setHeight(props.revealed ? `auto` : 0)
  }, [])

  const handleToggle = () => {
    setHeight(height === 0 ? `auto` : 0)
  }

  return (
    <li className="service-item">
      <h3 className="service-item__title" onClick={() => handleToggle()}>
        {props.title}
        <div className="service-item__toggle" onClick={() => handleToggle()}>
          {height ? <SymbolX /> : <ChevronDown />}
        </div>
      </h3>
      <AnimateHeight duration={500} height={height}>
        <div className="service-item__body">{props.children}</div>
      </AnimateHeight>
    </li>
  )
}

export default ServiceItem
