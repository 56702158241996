import React, { useEffect } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Services from "../components/Services"
import ServiceItems from "../components/ServiceItems"
import FeaturedWork from "../components/FeaturedWork"
import PageLink from "../components/PageLink"
import Chevron from "../components/Symbols/chevron"
import Gallery from "../components/Gallery"

const ChildPage = props => {
  const {
    data: { allWp, allWpPage },
    dispatch,
    location,
    transitionStatus,
  } = props

  const {
    background,
    title,
    slug,
    seo,
    ancestors,
    hero,
    childContent,
    serviceItems,
    featuredWork,
    imageCarousel,
  } = allWpPage.edges[0].node

  useEffect(() => {
    // if first load change bg colour instantly,
    // else from internal link, delay for swipe transition
    if (transitionStatus === "exited") {
      setTimeout(() => {
        dispatch(toggleBackground(background.backgroundColour))
        dispatch(toggleStickyHeaderColour(background.backgroundColour))
      }, 500)
    } else {
      dispatch(toggleBackground(background.backgroundColour))
      setTimeout(() => {
        dispatch(toggleStickyHeaderColour(background.backgroundColour))
      }, 1000)
    }
  }, [])

  useEffect(() => {
    // update breadcrumbs state
    dispatch(
      setBreadcrumbs({
        current: {
          title: title,
          path: slug,
        },
        parent: {
          title: ancestors.nodes[0].slug,
          path: `/${ancestors.nodes[0].slug}`,
          colour: background.backgroundColour,
        },
      })
    )
  }, [])

  return (
    <SiteLayout location={location}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <Hero
        slogan={hero.heroSlogan}
        halfHeight={true}
        scrollMarker={false}
        transitionStatus={transitionStatus}
      />

      <main id="main" className="main-content main-content--half">
        <section id="intro" className="section section--no-space-bottom">
          <div className="container-fluid">
            <div className="row middle-bp3">
              <div className="col-bp1-12 col-bp3-5">
                <div
                  className="intro"
                  dangerouslySetInnerHTML={{ __html: childContent.childIntro }}
                />
              </div>
              <div className="col-bp1-12 col-bp3-6 col-bp3-offset-1">
                <div
                  className="entry entry--child-intro"
                  dangerouslySetInnerHTML={{
                    __html: childContent.childIntroContent,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        {imageCarousel?.imageCarousel && (
          <section id="gallery" className="section section--gallery">
            <Gallery images={imageCarousel.imageCarousel} />
          </section>
        )}

        <section className="section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                {serviceItems.serviceItems !== null ? (
                  <ServiceItems items={serviceItems.serviceItems} />
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <section className="section section--no-space-bottom section--black">
          <div className="container-fluid">
            <div className="row middle-bp3">
              <div className="col-bp1-12 col-bp3-6">
                <h2
                  className="title title--project-footer"
                  dangerouslySetInnerHTML={{
                    __html: allWp.edges[0].node.options.options.servicesSlogan,
                  }}
                />
              </div>
              <div className="col-bp1-12 col-bp3-6">
                <PageLink
                  className="link link--right"
                  url="/creative"
                  dispatch={props.dispatch}
                  colour="blue"
                  menuLink={false}
                >
                  <span>View all services </span> <Chevron />
                </PageLink>
              </div>
            </div>
          </div>
        </section>

        <Services
          dispatch={dispatch}
          noTitle
          noSpaceBottom={featuredWork.featuredWorkItems ? true : null}
        />

        {featuredWork.featuredWorkItems ? (
          <FeaturedWork featuredWork={featuredWork} lightMode={true} />
        ) : null}
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
    background: state.background.background,
  }),
  null
)(ChildPage)

export const pageQuery = graphql`
  query GET_CHILD_PAGES($id: String) {
    allWp {
      edges {
        node {
          options {
            options {
              servicesSlogan
            }
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          uri
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          ancestors {
            nodes {
              slug
            }
          }
          background {
            backgroundColour
          }
          hero {
            heroSlogan
          }
          childContent {
            childIntro
            childIntroContent
          }
          imageCarousel {
            imageCarousel {
              carouselImage {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          serviceItems {
            serviceItems {
              serviceItemTitle
              serviceItemContent
            }
          }
          featuredWork {
            featuredWorkItems {
              featuredWorkItem {
                ... on WpProject {
                  id
                  slug
                  uri
                  title
                  projectFields {
                    projectLogoLightMode {
                      altText
                      sourceUrl
                    }
                    projectLogoDarkMode {
                      altText
                      sourceUrl
                    }
                    projectShortDesc
                    projectSubnav {
                      projectSubnavItem {
                        ... on WpPage {
                          id
                          title
                          uri
                          parent {
                            ... on WpPage {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
