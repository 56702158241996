import React from "react"
import ServiceItem from "./ServiceItem"

const ServiceItems = props => {
  return (
    <ul className="service-items">
      {props.items !== undefined
        ? props.items.map((item, i) => {
            return (
              <ServiceItem
                key={i}
                title={item.serviceItemTitle}
                revealed={i === 0 ? true : false}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: item.serviceItemContent }}
                />
              </ServiceItem>
            )
          })
        : null}
    </ul>
  )
}

export default ServiceItems
